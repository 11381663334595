import { useState } from 'react';
import { Search } from 'react-feather';
import {
	IconButton,
	InputAdornment,
	TextField,
	Typography,
} from '@mui/material';
import './SearchReseller.css';
import CustomTable from '../../CommonComponents/Table/CustomTable';
import { searchResellerService } from '../../Services/resellerServices';

const searchReseller = () => {
	const [searchTerm, setSearchTerm] = useState('');
	const [searchResult, setSearchResult] = useState([]);
	const [errorMessage, setErrorMessage] = useState('');

	const handleSearch = () => {
		if (searchTerm === '') {
			setErrorMessage('Please enter 3 characters at least!');
		} else {
			setErrorMessage('');

			searchResellerService(searchTerm)
				.then((result) => {
					if (result.length === 0) {
						setErrorMessage(`No results have been found. (${searchTerm})`);
					} else {
						setSearchResult(result);
					}
				})
				.catch(() => {
					setErrorMessage('Something went wrong, Please try again!');
				});
		}
	};
	return (
		<div className="searchWrapper">
			<Typography
				noWrap
				sx={{
					color: '#151C2D',
					fontSize: '1rem',
					fontStyle: 'normal',
					fontWeight: 600,
					fontFamily: 'Arial, Helvetica, sans-serif',
				}}
			>
				Search Reseller
			</Typography>
			<hr />
			<div className="searchInputWrapper">
				<div className="searchInput">
					<TextField
						variant="outlined"
						placeholder="Please enter 3 character to search"
						value={searchTerm}
						onChange={(e) => {
							setSearchTerm(e.target.value);
						}}
						sx={{
							'& .MuiOutlinedInput-input': {
								height: '100%',
								padding: '10px',
							},
							'& input::placeholder': {
								fontSize: '14px',
							},
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={handleSearch}
										edge="end"
										sx={{ width: '34px' }}
									>
										<Search />
									</IconButton>
								</InputAdornment>
							),
						}}
						fullWidth
					/>
				</div>
				<div>
					<p className="error-text">{errorMessage}</p>
				</div>
			</div>

			<div className="searchTable">
				<CustomTable searchResult={searchResult} errorMessage={errorMessage} />
			</div>
			<br />
			<p className="error-text">
				Please be aware that the information is confidential and for internal
				use only!
			</p>
			<br />
			<br />
		</div>
	);
};

export default searchReseller;
