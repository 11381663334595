export const baseUrl = 'https://localhost:8443/partsexl';

export const reseller = {
	allResellerList: '/reseller/getResellersList/',
	searchReseller: '/reseller/findResellerByCompany?Name=:Name',
};

export const mpc = {
	search: '/mpc/getMpcByFilte/:selectedCriteria',
	getAllCountries: '/mpc/getAllCountries',
};
export const userService = {
	userInfo: '/reseller/userInfo',
};
