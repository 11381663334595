import { Grid } from '@mui/material';
import CustomButton from '../../CommonComponents/Button/CustomButton';
import CustomTable from '../../CommonComponents/Table/CustomTable';
import AutoCompleteCountryDropdown from '../../CommonComponents/Dropdown/AutoCompleteCountryDropdown';
import CustomTextField from '../../CommonComponents/TextField/CustomTextField';
import './VerificationOfReseller.css';
const VerificationOfReseller = () => {
	return (
		<div className="VerificationOfResellerWrapper">
			<Grid container>
				<Grid item>
					<AutoCompleteCountryDropdown
						width="90%"
						height="2.5em"
						placeholder="Identifying Country"
					/>
					<br />
					<CustomTextField placeholder="Select file format" height="2.5em" />
					<br />
					<CustomButton text="Start export" width="10em" />
				</Grid>
				<br />

				<Grid item>
					<CustomTable />
				</Grid>
			</Grid>
		</div>
	);
};

export default VerificationOfReseller;
