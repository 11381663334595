import { useState, useEffect } from 'react';
import { Formik } from 'formik';
import {
	Typography,
	Card,
	Grid,
	Checkbox,
	FormControlLabel,
	Paper,
	Button,
	FormControl,
	OutlinedInput,
} from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import './AddNewReseller.css';
import CustomButton from '../../CommonComponents/Button/CustomButton';
import CustomTextField from '../../CommonComponents/TextField/CustomTextField';
import CustomCountryDropdown from '../../CommonComponents/Dropdown/CustomCountryDropdown';
import { getCountryListService } from '../../Services/getCountryListService';
import AutoCompleteCountryDropdown from '../../CommonComponents/Dropdown/AutoCompleteCountryDropdown';

const AddNewReseller = () => {
	const [countryList, setCountryList] = useState({});
	const checkBoxlabel = { inputProps: { 'aria-label': 'Checkbox demo' } };

	const salesActivitycountryList = {
		country: [
			{
				label: 'Bulgaria',
				active: true,
			},
			{
				label: 'Austria',
				active: true,
			},
			{
				label: 'Belgium',
				active: true,
			},
			{
				label: 'Croatia',
				active: true,
			},
			{
				label: 'Cyprus',
				active: true,
			},
			{
				label: 'Czech Republic',
				active: true,
			},
			{
				label: 'estonia',
				active: true,
			},
			{
				label: 'finland',
				active: true,
			},
			{
				label: 'French',
				active: true,
			},
			{
				label: 'Germany',
				active: true,
			},
			{
				label: 'Greece',
				active: true,
			},
			{
				label: 'hungary',
				active: true,
			},
			{
				label: 'italy',
				active: true,
			},
			{
				label: 'Iceland',
				active: true,
			},
			{
				label: 'Latvia',
				active: true,
			},
			{
				label: 'Lithuania',
				active: true,
			},
			{
				label: 'Liechtenstein',
				active: true,
			},
			{
				label: 'Luxembourg',
				active: true,
			},
			{
				label: 'Malta',
				active: true,
			},
			{
				label: 'Netherlands',
				active: true,
			},

			{
				label: 'Norway',
				active: true,
			},
			{
				label: 'poland',
				active: true,
			},
			{
				label: 'portugal',
				active: true,
			},
			{
				label: 'Romania',
				active: true,
			},

			{
				label: 'slovakia',
				active: true,
			},
			{
				label: 'Slovenia',
				active: true,
			},
			{
				label: 'Spain',
				active: true,
			},
			{
				label: 'Swedish',
				active: true,
			},
			{
				label: 'Switzerland',
				active: true,
			},
			{
				label: 'United Kingdom',
				active: true,
			},
		],
	};
	const countryLists = [
		{
			countryisocode: 'BE',
			countryname: 'Belgium',
			exEuEfta: 0,
		},
		{
			countryisocode: 'BG',
			countryname: 'Bulgaria',
			exEuEfta: 0,
		},
		{
			countryisocode: 'CY',
			countryname: 'Cyprus',
			exEuEfta: 0,
		},
		{
			countryisocode: 'EE',
			countryname: 'Estonia',
			exEuEfta: 0,
		},
		{
			countryisocode: 'ES',
			countryname: 'Spain',
			exEuEfta: 0,
		},
		{
			countryisocode: 'GR',
			countryname: 'Greece',
			exEuEfta: 0,
		},
		{
			countryisocode: 'IE',
			countryname: 'Ireland',
			exEuEfta: 0,
		},
		{
			countryisocode: 'LI',
			countryname: 'Liechtenstein',
			exEuEfta: 0,
		},
		{
			countryisocode: 'LT',
			countryname: 'Lithuania',
			exEuEfta: 0,
		},
		{
			countryisocode: 'LU',
			countryname: 'Luxembourg',
			exEuEfta: 0,
		},
		{
			countryisocode: 'LV',
			countryname: 'Latvia',
			exEuEfta: 0,
		},
		{
			countryisocode: 'MT',
			countryname: 'Malta',
			exEuEfta: 0,
		},
		{
			countryisocode: 'NO',
			countryname: 'Norway',
			exEuEfta: 0,
		},
		{
			countryisocode: 'PL',
			countryname: 'Poland',
			exEuEfta: 0,
		},
		{
			countryisocode: 'RO',
			countryname: 'Romania',
			exEuEfta: 0,
		},
		{
			countryisocode: 'SE',
			countryname: 'Sweden',
			exEuEfta: 0,
		},
		{
			countryisocode: 'SI',
			countryname: 'Slovenia',
			exEuEfta: 0,
		},
		{
			countryisocode: 'SK',
			countryname: 'Slovakia',
			exEuEfta: 0,
		},
		{
			countryisocode: 'HR',
			countryname: 'Croatia',
			exEuEfta: 0,
		},
		{
			countryisocode: 'XX',
			countryname: 'Further country/countries',
			exEuEfta: 1,
		},
		{
			countryisocode: 'TR',
			countryname: 'Turkey',
			exEuEfta: 1,
		},
		{
			countryisocode: 'GB',
			countryname: 'United Kingdom',
			exEuEfta: 0,
		},
		{
			countryisocode: 'FI',
			countryname: 'Finland',
			exEuEfta: 0,
		},
		{
			countryisocode: 'DK',
			countryname: 'Denmark',
			exEuEfta: 0,
		},
		{
			countryisocode: 'PT',
			countryname: 'Portugal',
			exEuEfta: 0,
		},
		{
			countryisocode: 'CZ',
			countryname: 'Czech Republic',
			exEuEfta: 0,
		},
		{
			countryisocode: 'RU',
			countryname: 'Russia',
			exEuEfta: 1,
		},
		{
			countryisocode: 'AT',
			countryname: 'Austria',
			exEuEfta: 0,
		},
		{
			countryisocode: 'NL',
			countryname: 'Netherlands',
			exEuEfta: 0,
		},
		{
			countryisocode: 'IT',
			countryname: 'Italy',
			exEuEfta: 0,
		},
		{
			countryisocode: 'FR',
			countryname: 'France',
			exEuEfta: 0,
		},
		{
			countryisocode: 'HU',
			countryname: 'Hungary',
			exEuEfta: 0,
		},
		{
			countryisocode: 'IS',
			countryname: 'Iceland',
			exEuEfta: 0,
		},
		{
			countryisocode: 'DE',
			countryname: 'Germany',
			exEuEfta: 0,
		},
		{
			countryisocode: 'CH',
			countryname: 'Switzerland',
			exEuEfta: 0,
		},
	];

	useEffect(() => {
		getCountryListService()
			.then((country) => {
				setCountryList(country);
			})
			.catch(() => {
				setCountryList([]);
			});
	}, []);

	return (
		<div className="addNewResellerWrapper">
			<Typography
				noWrap
				sx={{
					color: '#151C2D',
					fontSize: '14px',
					fontStyle: 'normal',
					fontWeight: 600,
					fontFamily: 'Arial, Helvetica, sans-serif',
				}}
			>
				Add New Reseller
			</Typography>
			<hr />
			<div className="addNewResellerContent">
				<Card
					sx={{
						padding: '2em 1.5em',
					}}
				>
					<Formik>
						<Grid container width="100%" flexDirection={'column'}>
							<Typography
								sx={{
									fontWeight: 600,
									paddingBottom: '10px',
								}}
							>
								Company
							</Typography>
							<Grid item className="companyDetails">
								<Grid item width="100%">
									<CustomTextField
										placeholder="Company name"
										width="90%"
										height="2.5em"
									/>
									<CustomTextField
										placeholder="Alternative Number"
										width="90%"
										height="2.5em"
									/>

									<CustomTextField
										placeholder="Commercial registration number"
										width="90%"
										height="2.5em"
									/>
								</Grid>
								<Grid item>
									<Typography>
										Public Corporation not a company constituted under civil
										laws and registered sole traders
										<Checkbox {...checkBoxlabel} defaultChecked />
									</Typography>
								</Grid>
							</Grid>
							<br />
							<br />

							<Typography
								sx={{
									fontWeight: 600,
									paddingBottom: '10px',
								}}
							>
								Address and contact data:
							</Typography>
							<Grid item className="address">
								<Grid item>
									<Grid item width="32%">
										<CustomTextField
											placeholder="Address"
											width="90%"
											height="2.5em"
										/>
									</Grid>
									<Grid item width="32%">
										<CustomTextField
											placeholder="House Address"
											width="90%"
											height="2.5em"
										/>
									</Grid>
									<Grid item width="32%">
										<CustomTextField
											placeholder="ZIP"
											width="90%"
											height="2.5em"
										/>
									</Grid>
								</Grid>
								<br />
								<Grid item>
									<Grid item width="32%">
										<CustomTextField
											placeholder="City"
											width="90%"
											height="2.5em"
										/>
									</Grid>
									<Grid item width="32%">
										<AutoCompleteCountryDropdown
											width="90%"
											height="2.5em"
											placeholder="Country"
										/>
									</Grid>
									<Grid item width="32%">
										<CustomTextField
											placeholder="Telephone Number"
											width="90%"
											height="2.5em"
										/>
									</Grid>
								</Grid>
								<br />
								<Grid item>
									<CustomTextField
										placeholder="Fax Number"
										width="30%"
										height="2.5em"
									/>
								</Grid>
							</Grid>
							<br />
							<br />
							<Typography
								sx={{
									fontWeight: 600,
									paddingBottom: '10px',
								}}
							>
								Further Information
							</Typography>
							<Grid item className="futherInformation">
								<Grid item>
									<Grid item width="32%">
										<CustomTextField
											placeholder="Homepage"
											width="90%"
											height="2.5em"
										/>
									</Grid>
									<Grid item width="32%">
										<AutoCompleteCountryDropdown
											width="90%"
											height="2.5em"
											placeholder="Identifying country"
										/>
									</Grid>
									<Grid item width="32%">
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DemoContainer components={['DatePicker']}>
												<DatePicker
													label="Date Created"
													slotProps={{ textField: { size: 'small' } }}
												/>
											</DemoContainer>
										</LocalizationProvider>
									</Grid>
								</Grid>
								<br />
								<Grid
									item
									width="30%"
									alignItems={'center'}
									className="division"
								>
									<Typography>Division : </Typography>
									<FormControlLabel
										width="25%"
										control={<Checkbox name="Austria" />}
										label="Bus"
									/>
									<FormControlLabel
										width="25%"
										control={<Checkbox name="Austria" />}
										label="Truck"
									/>
								</Grid>
							</Grid>
							<br />
							<br />
							<Typography
								sx={{
									fontWeight: 600,
									paddingBottom: '10px',
								}}
							>
								Known sales activity:
							</Typography>
							<Grid item className="knowActivities" width="100%">
								<Grid item className="countryCheckBoxWrapper">
									{salesActivitycountryList?.country?.map((country) => {
										return (
											<Grid item className="countries" width="25%">
												<FormControlLabel
													control={<Checkbox name={country.label} />}
													label={
														<Typography style={{ fontSize: '14px' }}>
															{country.label}
														</Typography>
													}
												/>
											</Grid>
										);
									})}
								</Grid>
							</Grid>
							<br />
							<br />
							<Typography
								sx={{
									fontWeight: 600,
									paddingBottom: '10px',
								}}
							>
								Remarks
							</Typography>
							<Grid item className="dataInput" width="100%">
								<FormControl sx={{ width: '100%' }}>
									<OutlinedInput
										placeholder="(Data input in English only)"
										multiline
										rows={5}
									/>
								</FormControl>
							</Grid>
							<br />
							<br />
							<Typography
								sx={{
									fontWeight: 600,
									paddingBottom: '10px',
								}}
							>
								Evidences (Documents etc.):
							</Typography>
							<Grid item className="documents" width="100%">
								<Paper
									className="fileUpload"
									style={{
										paddingTop: '3rem',
										paddingBottom: '1em',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<label htmlFor="fileInput">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="100"
											height="70"
											viewBox="0 0 146 122"
											fill="none"
										>
											<path
												d="M73 62.0176V119"
												stroke="#C8C8C8"
												strokeWidth="6"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M125.798 100.684C131.932 97.3087 136.778 91.9673 139.571 85.5031C142.364 79.039 142.945 71.8201 141.221 64.9859C139.498 58.1518 135.569 52.0915 130.054 47.7616C124.539 43.4316 117.752 41.0787 110.765 41.074H102.84C100.937 33.6417 97.3882 26.7417 92.4621 20.8928C87.5359 15.0439 81.3601 10.3982 74.399 7.30508C67.4379 4.21193 59.8727 2.75179 52.272 3.03444C44.6713 3.31709 37.233 5.33516 30.5163 8.93695C23.7996 12.5387 17.9793 17.6305 13.493 23.8295C9.00659 30.0284 5.97092 37.1732 4.61417 44.7268C3.25742 52.2803 3.61489 60.046 5.65972 67.44C7.70455 74.834 11.3835 81.664 16.42 87.4163"
												stroke="#C8C8C8"
												strokeWidth="6"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M99.4562 86.4386L73.0001 62.0176L46.5439 86.4386"
												stroke="#C8C8C8"
												strokeWidth="6"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
										<Typography
											sx={{
												width: 'auto',
												height: '2rem',
												textAlign: 'center',
												fontSize: '0.875rem',
												fontStyle: 'normal',
												fontWeight: 400,
												lineHeight: '2.5rem',
												color: '#000',
											}}
										>
											Drag And Drop
										</Typography>

										<Typography
											sx={{
												width: '11.25rem',
												height: '2rem',
												textAlign: 'center',
												fontSize: '0.875rem',
												fontStyle: 'normal',
												fontWeight: 400,
												lineHeight: '2.5rem',
												color: '#000',
											}}
										>
											or
										</Typography>
									</label>

									<Button
										component="label"
										sx={{
											background: '#E6E6E6',
											color: '#000',
											display: 'flex',
											width: '14.5rem',
											height: '2.5rem',
											justifyContent: 'center',
											alignItems: 'center',
											flexShrink: 0,
											borderRadius: '.175rem',
											border: '1px solid',
											textTransform: 'none',
											'&:hover ': {
												transition: 'background 0.3s',
												backgroundColor: '#fff !important',
												color: '#194253',

												border: '1px solid',
											},
										}}
									>
										Upload from the device
										<input
											type="file"
											accept="/*"
											hidden
											id="fileInput"
											multiple
										/>
									</Button>
								</Paper>
							</Grid>
							<br />
							<Grid
								item
								className="btn"
								width="100%"
								display={'flex'}
								justifyContent={'flex-end'}
								marginTop="2em"
							>
								<CustomButton text="cancel" width="10em" />
								<CustomButton text="save" width="10em" marginLeft="2em" />

								<br />
								<br />
							</Grid>
						</Grid>
					</Formik>
				</Card>
			</div>
			<br />
			<p className="red-text" style={{ marginBottom: '5em', fontSize: '14px' }}>
				Attention: Due to General Data Protection Regulation (GDPR) sole
				traders/one-man-businesses and consumers are not included.
			</p>
		</div>
	);
};

export default AddNewReseller;
