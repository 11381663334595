import axios from 'axios';
import { baseUrl, reseller } from '../Constants/APIUrls';

export const searchResellerService = async (searchTerm) => {
	try {
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
			},
		});
		const response = await axiosInstance.get(
			`${baseUrl}${reseller.searchReseller.replace(':Name', searchTerm)}`
		);

		const results = response ? response.data : [];
		return results;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
