import * as React from 'react';

import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import CustomPagination from './CustomPagination';

const CustomTable = (props) => {
	console.log(props?.searchResult.length, props?.errorMessage.length);
	const colheaders = [
		{
			key: 'name',
			name: 'Company Name',
		},
		{
			key: 'address',
			name: 'Address',
		},
		{
			key: 'alternativename',
			name: 'Alternative name',
		},
		{
			key: 'identifiyingcountry',
			name: 'Identifying country',
		},
		{
			key: 'remarks',
			name: 'Remarks (Do not enter personal data)',
		},
		{
			key: 'divisionalfocus',
			name: 'Divisions',
		},
	];

	return (
		<Grid>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							{colheaders.map((header) => (
								<TableCell align="center">{header.name}</TableCell>
							))}
						</TableRow>
					</TableHead>

					<TableBody>
						{props?.searchResult.length === 0 ||
						props?.errorMessage.length !== 0 ? (
							<TableRow>
								<TableCell></TableCell>
							</TableRow>
						) : (
							props?.searchResult.map((data, index) => (
								<TableRow key={index}>
									{colheaders.map((header) => (
										<TableCell align="center">{data[header.key]}</TableCell>
									))}
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<br />
			<Grid className="pagination">
				{props.searchResult.length !== 0 && <CustomPagination />}
			</Grid>
		</Grid>
	);
};
export default CustomTable;
