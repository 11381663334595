import { useEffect, useState } from 'react';
import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
	useMsal,
	useAccount,
} from '@azure/msal-react';
import { useSelector, useDispatch } from 'react-redux';
import Router from './Router';
import { loginRequest } from './auth.config';
// import { getUserInfo } from './services/UserService';
import { login } from './Store/authSlice';
import { setCurrentRole } from './Store/roleSlice';

const Pages = () => {
	const { instance, accounts } = useMsal();
	const activeAccount = instance.getActiveAccount();
	const account = useAccount(accounts[0] || {});
	const dispatch = useDispatch();
	const user = useSelector((state) => state?.auth?.user);
	// const [unauthorized, setUnauthorized] = useState(false);
	const [forbidden, setForbidden] = useState(false);

	async function callHandleRedirectPromise(instanceObj) {
		await instanceObj.handleRedirectPromise;
	}
	useEffect(() => {
		callHandleRedirectPromise(instance);

		if (account) {
			localStorage.setItem('AccessToken', account.idToken);
			if (!user) {
				// getUserInfo()
				// 	.then((userInfo) => {
				// 		dispatch(login(userInfo));
				// 		const currentRole = userInfo?.accessMatrix[0]?.role;
				// 		dispatch(setCurrentRole(currentRole));
				// 	})
				// 	.catch((err) => {
				// 		console.warn(err);
				// 		if (err?.response?.status === 401) {
				// 			// setUnauthorized(true);
				// 		} else if (err?.response?.status === 403) {
				// 			setForbidden(true);
				// 		}
				// 	});
			}
		}
	}, [account, instance]);

	const handleRedirect = () => {
		instance
			.loginRedirect({ ...loginRequest, prompt: 'login' })
			.catch((error) => console.error(error));
	};

	return (
		<div>
			<UnauthenticatedTemplate>
				{activeAccount?.idToken !== undefined ? null : handleRedirect()}
			</UnauthenticatedTemplate>
			<AuthenticatedTemplate>
				{account && !forbidden ? (
					<Router />
				) : (
					<h1>
						{
							"You don't have permission to access media hub. Please contact the admin"
						}
					</h1>
				)}
			</AuthenticatedTemplate>
		</div>
	);
};

export default Pages;
