import { useState } from 'react';
import './Header.css';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../CommonComponents/Language/LanguageSelector';
import { NavLink } from 'react-router-dom';
import Logo from '../../Resources/images/HeaderLogoImage32.png';
import { useSelector } from 'react-redux';

import {
	AppBar,
	Toolbar,
	IconButton,
	Box,
	Drawer,
	Typography,
	Avatar,
} from '@mui/material';
import Footer from '../Footer/Footer';
import AccountSettings from './AccountSettings';
import { roles } from '../../Constants/Permission';

const Header = (props) => {
	const { t } = useTranslation();
	const [openSettings, setOpenSettings] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const user_info = useSelector((state) => state?.auth?.user);
	const currentRole = useSelector((state) => state?.role?.currentRole);

	console.log('currentRole', currentRole);

	const handleProfileClick = (event) => {
		setOpenSettings(true);
		setAnchorEl(event.currentTarget);
	};
	const handleProfileClose = () => {
		setOpenSettings(false);
		setAnchorEl(null);
	};

	const list = () => {
		return (
			<div className="NavBar">
				<div className="NavBarTop">
					<div className="headerMenu">
						<ul className="nav-NavLinks">
							<NavLink to="/" className="menuItem">
								{t('home')}
							</NavLink>

							<NavLink to="/search-reseller" className="menuItem">
								{t('navlevel1_1')}
							</NavLink>

							<NavLink to="/add-new-reseller" className="menuItem">
								{t('navlevel1_2')}
							</NavLink>
							<NavLink to="/verification-of-reseller" className="menuItem">
								{t('navlevel1_3')}
							</NavLink>
							<NavLink to="/edit-reseller" className="menuItem">
								{t('navlevel1_4')}
							</NavLink>
							<NavLink to="/export-data" className="menuItem">
								{t('navlevel1_5')}
							</NavLink>
							<NavLink to="/data-review" className="menuItem">
								{t('navlevel1_6')}
							</NavLink>
							<NavLink to="/maintain-contact" className="menuItem">
								{t('navlevel1_7')}
							</NavLink>
							<NavLink to="/KPI-report" className="menuItem">
								{t('navlevel1_8')}
							</NavLink>
							<NavLink to="/download" className="menuItem">
								{t('navlevel1_9')}
							</NavLink>
							<NavLink to="/help" className="menuItem">
								{t('navlevel1_10')}
							</NavLink>
						</ul>
					</div>
				</div>
				<Footer />
			</div>
		);
	};
	return (
		<div className="headerWrapper">
			<Box sx={{ display: 'flex' }}>
				<AppBar
					position="fixed"
					style={{
						background: '#fff',
						color: '#6A6A75',
						height: '4rem',
					}}
				>
					<Toolbar>
						<Drawer
							variant="permanent"
							open
							sx={{
								display: 'block',
								'& .MuiDrawer-paper': {
									boxSizing: 'border-box',
									width: '21%',
									backgroundColor: '#E6E6E6',
									overflow: 'hidden',
								},
							}}
						>
							<div>
								<div className="logo">
									<img src={Logo} className="headerLogo" />
								</div>
								<div>{list()}</div>
							</div>
						</Drawer>
						<Typography
							noWrap
							sx={{
								color: '#151C2D',
								position: 'relative',
								left: '24%',
								fontFamily: 'Roboto',
								fontSize: '1rem',
								fontStyle: 'normal',
								fontWeight: 600,
								lineHeight: '2.5rem',
								fontFamily: 'Arial, Helvetica, sans-serif',
							}}
						>
							{/* {`Welcome ${user_info.LAST_NAME} , ${user_info.FIRST_NAME}
						(${user_info.USER_ID}) `} */}
							Welcome
						</Typography>
						<Box sx={{ flexGrow: 1, alignItems: 'center' }} />
						<div data-test-id="languageSelector">
							<LanguageSelector />
						</div>

						<IconButton aria-label="avatar" onClick={handleProfileClick}>
							<Avatar alt="Nandini" src="/" />
						</IconButton>
						<AccountSettings
							anchorEl={anchorEl}
							open={openSettings}
							handleClose={handleProfileClose}
						/>
					</Toolbar>
				</AppBar>
			</Box>
		</div>
	);
};

export default Header;
