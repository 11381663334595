import { useState, useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { getCountryListService } from '../../Services/getCountryListService';

const AutoCompleteCountryDropdown = (props) => {
	const [countryList, setCountryList] = useState([]);
	const styles = {
		textField: {
			'& .MuiInputBase-root': {
				color: '#000',
				background: '#fff',
				flexShrink: 0,
				borderRadius: '.175rem',
				border: ' 1px #E5E5E5',
				padding: '0 1rem',
				width: `${props.width}`,
				height: `${props.height}`,
				//maxHeight: `2.5rem`,
			},
			'& input::placeholder': {
				fontSize: '14px',
			},

			'& .Mui-focused': {
				maxHeight: `unset`,
			},
		},
	};
	useEffect(() => {
		getCountryListService().then((country) => {
			setCountryList(country);
		});
	}, []);

	return (
		<Autocomplete
			id="autocomplete"
			noOptionsText={countryList?.length && 'no Matches Found'}
			options={countryList?.map((countryList) => countryList.countryname)}
			sx={{ width: 300 }}
			renderInput={(params) => (
				<TextField
					sx={styles.textField}
					{...params}
					placeholder={props?.placeholder}
				/>
			)}
		/>
	);
};

AutoCompleteCountryDropdown.propTypes = {
	id: PropTypes.any,
	key: PropTypes.any,
	name: PropTypes.any,
	handleChange: PropTypes.any,
	placeholder: PropTypes.any,
};
export default AutoCompleteCountryDropdown;
