import './Home.css';
import { useTranslation } from 'react-i18next';

const Home = () => {
	const { t } = useTranslation();

	return (
		<div className="homeContainer">
			<br />
			<div className="homeContent">
				<p>Welcome to PartsExList!</p>
				<p>
					The PartsExList is a database to identify potential resellers and
					other customers that require{' '}
					<b> special attention in doing business with.</b>
				</p>
				<p>But please be aware of the following:</p>
				<p>
					A company included in the PartsExList is
					<b> not per se excluded as business partner.</b> However, selling to
					those entities is only permissible to the extent they can reasonably
					demonstrate their own needs of GenuineParts for the repair or
					maintenance of vehicles, aggregates or components of the respective
					Daimler Truck brand.
				</p>

				<p>
					If despite exercising due care, the authorized repairer cannot clearly
					determine whether the customer is an independent spare parts dealer,
					and if doubts cannot be resolved, he may sell to the independent
					(possibly) spare parts dealer.
				</p>
				<p>
					The information held within this database is confidential and for
					internal use only!
				</p>

				<p className="red-text alert-text">
					Attention: Due to General Data Protection Regulation (GDPR) sole
					traders/one-man-businesses and consumers are not included.
				</p>
			</div>
		</div>
	);
};

export default Home;
